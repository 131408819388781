import API from '../api/index';
import IActividad from '../types/Actividad';
import IEmpleado from '../types/Empleado';
import IHoliday from '../types/Holiday';
import IAsistenciaDoc from '../types/AsistenciaDoc';
import IMail from '../types/Mail';
import IOutOffice from '../types/OutOffice';
import ISala from '../types/Sala';
import IConsentimientoDoc from '../types/CI';

const getConfig = (id: string) => API.get(`/config${id}`);

const createEmpleado = (data: IEmpleado) => API.post('/empleado', data);
const updateEmpleado = (_id: string, data: IEmpleado) => API.patch(`/empleado${_id}`, data);

const createOutOffice = (data: IOutOffice) => API.post('/outoffice', data);
const updateOutOffice = (_id: string, data: IOutOffice) => API.patch(`/outoffice${_id}`, data);
const deleteOutOffice = (_id: string) => API.delete(`/outoffice${_id}`);

const createActividad = (data: IActividad) => API.post('/actividad', data);
const updateActividad = (_id: string, data: IActividad) => API.patch(`/actividad${_id}`, data);
const deleteActividad = (_id: string) => API.delete(`/actividad${_id}`);

const createSala = (data: ISala) => API.post('/sala', data);
const updateSala = (_id: string, data: ISala) => API.patch(`/sala${_id}`, data);
const deleteSala = (_id: string) => API.delete(`/sala${_id}`);

const updateFestivos =  (_id: string, data: IHoliday[]) => API.patch(`/holidays${_id}`, data);

const createMail = (data: IMail) => API.post('/mail', data);
const updateMail = (_id: string, data: IMail) => API.patch(`/mail${_id}`, data);

const createAsistenciaDoc = (data: IAsistenciaDoc) => API.post('/asistenciadoc', data);
const deleteAsistenciaDoc = (_id: string) => API.delete(`/asistenciadoc${_id}`);

const saveCI = (data: IConsentimientoDoc) => API.post('/consentimientodoc', data);
const updateCI = (_id: string, data: IConsentimientoDoc) => API.patch(`/consentimientodoc${_id}`, data);

const ConfigService = {
    getConfig,
    createEmpleado,
    updateEmpleado,
    createOutOffice,
    updateOutOffice,
    deleteOutOffice,
    createActividad,
    updateActividad,
    deleteActividad,
    createSala,
    updateSala,
    deleteSala,
    updateFestivos,
    createMail,
    updateMail,
    createAsistenciaDoc,
    deleteAsistenciaDoc,
    saveCI,
    updateCI
};

export default ConfigService;