import { createTheme } from '@mui/material/styles';
import { Alert, Avatar, Dialog, DialogContent, List, Typography, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox, Grid, CircularProgress, DialogActions, Button, DialogTitle, DialogContentText, TextField, MenuItem, Snackbar } from "@mui/material";
import LogService from "../../services/LogService";
import ILog from "../../types/Log";
import IMasterClass from "../../types/MasterClass";
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import AgendaService from '../../services/AgendaService';
import { useNavigate } from "react-router-dom";
import ClaseUtils from '../../utils/Clase';
import ConfigUtils from '../../utils/Config';
import IAlert from '../../types/Alert';
import ISala from '../../types/Sala';
import IActividad from '../../types/Actividad';
import * as constantes from "../../common/Constantes";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const locale = constantes.locale;

type EditActivitiesProps = {
    masterClasses: IMasterClass[],
    onClose: () => void,
    callBack: () => void
}

const EditActivities = (props: EditActivitiesProps) => {
    const theme = createTheme();
    const history = useNavigate();

    const [salas] = useState<Array<ISala>>(ConfigUtils.getSalas());
    const [actividades] = useState<Array<IActividad>>(ConfigUtils.getActividades());

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [checked, setChecked] = useState<Array<string>>([]);
    const [modalSelectEdit, setModalSelectEdit] = useState(false);
    const [modalEditProfesor, setModalEditProfesor] = useState(false);
    const [modalEditSala, setModalEditSala] = useState(false);
    const [smartEdit, setSmartEdit] = useState(false);
    const [especialistaClases, setEspecialistaClases] = useState<Array<number>>([]);
    const [salaClases, setSalaClases] = useState<Array<number>>([]);
    const [modalEditDates, setModalEditDates] = useState(false);
    const [datesEnd, setDatesEnd] = useState<Array<Date>>([]);

    const handleToggle = (item: IMasterClass) => {
        if (item._id === undefined || item._id === null) return;

        const currentValue: boolean = checked.includes(item._id);
        const newChecked = [...checked];

        if (!currentValue) {
            newChecked.push(item._id);
        } else {
            const index = checked.indexOf(item._id);
            newChecked.splice(index, 1);
        }

        setChecked(newChecked);
    };

    const editFechaFinal = () => {
        if (checked.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'No se han seleccionado clases a editar' });
            setModalSelectEdit(false);
            return;
        }
        setModalSelectEdit(false);
        const masterChecked = props.masterClasses.filter(m => m._id === checked[0])[0];
        const terminarRepetir = [];
        terminarRepetir.push(masterChecked.terminarRepetir);
        if (checked.length === 1) {
            setSmartEdit(true);
            setDatesEnd(datesEnd);
            setModalEditDates(true);
        } else {
            let continuar: boolean = false;
            for (let i = 1; i < checked.length; i++) {
                const nextMaster = props.masterClasses.filter(m => m._id === checked[i])[0];
                if (new Date(masterChecked.terminarRepetir).toDateString() === new Date(nextMaster.terminarRepetir).toDateString() && !continuar) {
                    setSmartEdit(true);
                    terminarRepetir.push(nextMaster.terminarRepetir);
                } else {
                    setSmartEdit(false);
                    continuar = true;
                    terminarRepetir.push(nextMaster.terminarRepetir);
                }
            }
        }
        setDatesEnd(terminarRepetir);
        setModalEditDates(true);
    };

    const editProfesor = () => {
        if (checked.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'No se han seleccionado clases a editar' });
            setModalSelectEdit(false);
            return;
        }
        setModalSelectEdit(false);
        const masterChecked = props.masterClasses.filter(m => m._id === checked[0])[0];
        const profesorClases = [];
        profesorClases.push(masterChecked.especialistaId);
        if (checked.length === 1) {
            setSmartEdit(true);
            setEspecialistaClases(profesorClases);
            setModalEditProfesor(true);
        } else {
            let continuar: boolean = false;
            for (let i = 1; i < checked.length; i++) {
                const nextMaster = props.masterClasses.filter(m => m._id === checked[i])[0];
                if (masterChecked.especialistaId === nextMaster.especialistaId && !continuar) {
                    setSmartEdit(true);
                    profesorClases.push(nextMaster.especialistaId);
                } else {
                    setSmartEdit(false);
                    continuar = true;
                    profesorClases.push(nextMaster.especialistaId);
                }
            }
        }
        setEspecialistaClases(profesorClases);
        setModalEditProfesor(true);
    };

    const editSala = () => {
        if (checked.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'No se han seleccionado clases a editar' });
            setModalSelectEdit(false);
            return;
        }
        setModalSelectEdit(false);
        const masterChecked = props.masterClasses.filter(m => m._id === checked[0])[0];
        const salas = [];
        salas.push(masterChecked.salaId);
        if (checked.length === 1) {
            setSmartEdit(true);
            setSalaClases(salas);
            setModalEditSala(true);
        } else {
            let continuar: boolean = false;
            for (let i = 1; i < checked.length; i++) {
                const nextMaster = props.masterClasses.filter(m => m._id === checked[i])[0];
                if (masterChecked.salaId === nextMaster.salaId && !continuar) {
                    setSmartEdit(true);
                    salas.push(nextMaster.salaId);
                } else {
                    setSmartEdit(false);
                    continuar = true;
                    salas.push(nextMaster.salaId);
                }
            }
        }
        setSalaClases(salas);
        setModalEditSala(true);
    };

    const close = () => {
        setChecked([]);
        setModalSelectEdit(false);
        setModalEditProfesor(false);
        setModalEditSala(false);
        setModalEditDates(false);
        setSmartEdit(false);
        setEspecialistaClases([]);
        setSalaClases([]);
        setDatesEnd([]);
        props.onClose();
    };

    const updateClass = () => {
        setLoading(true);
        let modifiedMasters: IMasterClass[] = [];
        let type: number = 0;

        if (modalEditDates) {
            type = 0;
            if (smartEdit) {

                for (let i = 0; i < checked.length; i++) {
                    let master: IMasterClass = props.masterClasses.filter(x => x._id === checked[i])[0];

                    //Comprobar que la fecha final es posterior a la inicial
                    if (new Date(new Date(master.start).toDateString()) > new Date(new Date(datesEnd[0]).toDateString())) {
                        setAlert({ open: true, severity: 'warning', msg: 'La fecha final no puede ser menos que la inicial' });
                        setLoading(false);
                        return;
                    }

                    master.terminarRepetir = datesEnd[0];
                    modifiedMasters.push(master);
                }

            } else {

                for (let i = 0; i < checked.length; i++) {
                    let master: IMasterClass = props.masterClasses.filter(x => x._id === checked[i])[0];

                    //Comprobar que la fecha final es posterior a la inicial
                    if (new Date(new Date(master.start).toDateString()) > new Date(new Date(datesEnd[i]).toDateString())) {
                        setAlert({ open: true, severity: 'warning', msg: 'La fecha final no puede ser menos que la inicial' });
                        setLoading(false);
                        return;
                    }

                    master.terminarRepetir = datesEnd[i];
                    modifiedMasters.push(master);
                }
            }
        }
        if (modalEditProfesor) {
            type = 1;
            if (smartEdit) {
                checked.map(c => {
                    let master: IMasterClass = props.masterClasses.filter(x => x._id === c)[0];
                    master.especialistaId = especialistaClases[0];
                    modifiedMasters.push(master);
                    return master;
                });
            } else {
                checked.map((c, index) => {
                    let master: IMasterClass = props.masterClasses.filter(x => x._id === c)[0];
                    master.especialistaId = especialistaClases[index];
                    modifiedMasters.push(master);
                    return master;
                });
            }
        }
        if (modalEditSala) {
            type = 2;
            if (smartEdit) {
                checked.map(c => {
                    let master: IMasterClass = props.masterClasses.filter(x => x._id === c)[0];
                    master.salaId = salaClases[0];
                    modifiedMasters.push(master);
                    return master;
                });
            } else {
                checked.map((c, index) => {
                    let master: IMasterClass = props.masterClasses.filter(x => x._id === c)[0];
                    master.salaId = salaClases[index];
                    modifiedMasters.push(master);
                    return master;
                });
            }
        }

        AgendaService.updateMasterClasses(modifiedMasters, type)
            .then((response: any) => {
                props.callBack();
                history(0);
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'EditActivities.Component.updateClass', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
                close();
            });
    };

    const onChangeEspecialistaClase = (event: any, index: number) => {
        const especialistas = [...especialistaClases];
        especialistas[index] = parseInt(event.target.value);
        setEspecialistaClases(especialistas);
    };

    const onChangeSalaClase = (event: any, index: number) => {
        const salas = [...salaClases];
        salas[index] = parseInt(event.target.value);
        setSalaClases(salas);
    };

    const onChangeFechaFinalClase = (date: any, index: number) => {
        const fechas = [...datesEnd]
        fechas[index] = date;
        setDatesEnd(fechas);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    try {

        return (
            <>
                {/* SELECCION DE CLASES */}
                <Dialog open={true} maxWidth="lg" fullWidth onClose={close}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>Selecciona las clases a editar</div>
                        </Typography>
                        <List>
                            {
                                props.masterClasses.map((item) => {

                                    return (
                                        <ListItem key={item._id}>
                                            <ListItemButton onClick={() => handleToggle(item)} dense>
                                                <ListItemIcon>
                                                    <Checkbox edge="start" tabIndex={-1} disableRipple checked={item._id !== undefined && item._id !== null && checked.includes(item._id)} />
                                                </ListItemIcon>
                                                <ListItemText primary={`${actividades.filter(a => a.internalID?.toString() == item.title.toString())[0].title} - ${ClaseUtils.formatClassTitle(item)}`} />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModalSelectEdit(true)}>Continuar</Button>
                        <Button onClick={close}>Cancelar</Button>
                    </DialogActions>
                </Dialog>
                {/* SELECCION EDICION */}
                <Dialog open={modalSelectEdit} maxWidth="lg">
                    <DialogTitle>EDICION DE CLASES</DialogTitle>
                    <>
                        <DialogContent sx={{ paddingBottom: 0 }}>
                            <DialogContentText>¿Qué necesitas editar de las clases?</DialogContentText>
                            {
                                checked.map((item) => {
                                    const clase = props.masterClasses.find(x => x._id === item);
                                    return (clase === undefined ? null : <p key={item}>{`${actividades.filter(a => a.internalID?.toString() === clase.title)[0].title} - ${ClaseUtils.formatClassTitle(clase)}`}</p>);
                                })
                            }
                        </DialogContent>
                        <DialogActions sx={{ flexDirection: "column" }}>
                            <Button onClick={editFechaFinal}>FECHA FINAL</Button>
                            <Button onClick={editProfesor}>PROFESOR</Button>
                            <Button onClick={editSala}>SALA</Button>
                            <Button onClick={close}>Cancelar</Button>
                        </DialogActions>
                    </>
                </Dialog>
                {/* EDICION PROFESOR */}
                <Dialog open={modalEditProfesor} maxWidth="lg">
                    <DialogContent>
                        <Typography variant="h5" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>Cambio de especialista</div>
                        </Typography>
                        {smartEdit ?
                            <TextField select value={especialistaClases[0]} variant="outlined" label="Especialista" margin="dense" fullWidth required onChange={(event) => setEspecialistaClases([parseInt(event.target.value)])} >
                                {
                                    ConfigUtils.getEmpleados().map((empleado: any, index: number) => {
                                        return (
                                            <MenuItem key={index} value={index}>{empleado.firstName}</MenuItem>
                                        );
                                    })
                                }
                            </TextField> :
                            <>
                                <DialogContentText id="alert-dialog-description">Cambia el especialista por clase</DialogContentText>
                                {
                                    checked.map((item, index) => {
                                        const master: IMasterClass = props.masterClasses.filter(x => x._id === item)[0];
                                        return (
                                            <div key={index}>
                                                <p>{`${actividades.filter(a => a.internalID?.toString() === master.title)[0].title} - ${ClaseUtils.formatClassTitle(master)}`}</p>
                                                <TextField select value={especialistaClases[index]} variant="outlined" label="Especialista" margin="dense" fullWidth required onChange={(event) => onChangeEspecialistaClase(event, index)} >
                                                    {
                                                        ConfigUtils.getEmpleados().map((empleado: any, index: number) => {
                                                            return (
                                                                <MenuItem key={index} value={index}>{empleado.firstName}</MenuItem>
                                                            );
                                                        })
                                                    }
                                                </TextField>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        }

                    </DialogContent>
                    {loading ?
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :
                        <DialogActions>
                            <DialogActions>
                                <Button onClick={updateClass}>Guardar</Button>
                                <Button onClick={close}>Cancelar</Button>
                            </DialogActions>
                        </DialogActions>
                    }
                </Dialog>
                {/* EDICION SALA */}
                <Dialog open={modalEditSala} maxWidth="lg">
                    <DialogContent>
                        <Typography variant="h5" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>Cambio de sala</div>
                        </Typography>
                        {smartEdit ?
                            <TextField select value={salaClases[0]} variant="outlined" label="Sala" margin="dense" fullWidth required onChange={(event) => setSalaClases([parseInt(event.target.value)])} >
                                {
                                    salas.map((sala, index) => {
                                        return (
                                            <MenuItem key={index} value={sala.internalID ? sala.internalID : index}>{sala.title}</MenuItem>
                                        );
                                    })
                                }
                            </TextField> :
                            <>
                                <DialogContentText id="alert-dialog-description">Cambia la sala por clase</DialogContentText>
                                {
                                    checked.map((item, index) => {
                                        const master: IMasterClass = props.masterClasses.filter(x => x._id === item)[0];
                                        return (
                                            <div key={index}>
                                                <p>{`${actividades.filter(a => a.internalID?.toString() === master.title)[0].title} - ${ClaseUtils.formatClassTitle(master)}`}</p>
                                                <TextField select value={salaClases[index]} variant="outlined" label="Sala" margin="dense" fullWidth required onChange={(event) => onChangeSalaClase(event, index)} >
                                                    {
                                                        salas.map((sala, index) => {
                                                            return (
                                                                <MenuItem key={index} value={sala.internalID ? sala.internalID : index}>{sala.title}</MenuItem>
                                                            );
                                                        })
                                                    }
                                                </TextField>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        }

                    </DialogContent>
                    {loading ?
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :
                        <DialogActions>
                            <DialogActions>
                                <Button onClick={updateClass}>Guardar</Button>
                                <Button onClick={close}>Cancelar</Button>
                            </DialogActions>
                        </DialogActions>
                    }
                </Dialog>
                {/* EDICIÓN FECHAS */}
                <Dialog open={modalEditDates} maxWidth="lg">
                    <DialogContent>
                        <Typography variant="h5" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>Cambio de fecha final</div>
                        </Typography>
                        {smartEdit ? 
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                <DatePicker 
                                    renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                    label="Fecha final"
                                    toolbarFormat="dd-mm-yyyy"
                                    value={datesEnd[0]}
                                    onChange={(date: any) => { date && setDatesEnd([date])}}
                                />
                            </LocalizationProvider> :
                            <>
                                <DialogContentText>Cambia la fecha final por clase</DialogContentText>
                                {
                                    checked.map((item, index) => {
                                        const master: IMasterClass = props.masterClasses.filter(x => x._id === item)[0];
                                        return (
                                            <div key={index}>
                                                <p>{`${actividades.filter(a => a.internalID?.toString() === master.title)[0].title} - ${ClaseUtils.formatClassTitle(master)}`}</p>
                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                                    <DatePicker 
                                                        renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                                        label="Fecha final"
                                                        toolbarFormat="dd-mm-yyyy"
                                                        value={datesEnd[index]}
                                                        onChange={(date) => onChangeFechaFinalClase(date, index)}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        ) 
                                    })
                                }
                            </>

                        }
                    </DialogContent>
                    {loading ?
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :
                        <DialogActions>
                            <DialogActions>
                                <Button onClick={updateClass}>Guardar</Button>
                                <Button onClick={close}>Cancelar</Button>
                            </DialogActions>
                        </DialogActions>
                    }
                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
        );

    } catch (e: any) {
        const error: ILog = { method: 'EditActivities.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default EditActivities;